import { render } from "./Stepper.vue?vue&type=template&id=2e88c9d5&scoped=true"
import script from "./Stepper.vue?vue&type=script&lang=ts"
export * from "./Stepper.vue?vue&type=script&lang=ts"

import "./Stepper.vue?vue&type=style&index=0&id=2e88c9d5&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-2e88c9d5"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon,QTooltip});
