<template>
  <iframe :id="'myFrame-' + campaignId" class="scenario-iframe" :src="iframeURL()" />

  <StartPushFormModal
    v-if="modalStartVisible"
    :modalVisible="modalStartVisible"
    v-model="campaign"
    :appId="appId"
    :isTestMode="isTestMode"
    :previousCampaign="previousCampaign"
    @updateStartSettings="updateStartSettings"
    @update:closeModal="onCloseModal"
  />
  <QuestionFormModal
    v-if="modalQuestionVisible"
    :modalVisible="modalQuestionVisible"
    :data="selectedEditItem"
    :campaign="campaign"
    :appId="appId"
    @update:closeModal="onCloseModal"
    @update:onSelect="editCardDone"
    @update:onCreate="addCardDone"
  />
  <QuestionSelectorModal
    v-if="modalSelectQuestionVisible"
    :modalVisible="modalSelectQuestionVisible"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelectNextQuestionDone"
  />
  <MessagesFormModal
    v-if="modalMessageVisible"
    :modalVisible="modalMessageVisible"
    :data="selectedEditItem"
    :campaign="campaign"
    :appId="appId"
    @update:closeModal="onCloseModal"
    @update:onSelect="editCardDone"
    @update:onCreate="addCardDone"
  />
  <MessageSelectorModal
    v-if="modalSelectMessageVisible"
    :modalVisible="modalSelectMessageVisible"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelectNextMessageDone"
  />
  <GoalFormModal
    v-if="modalGoalVisible"
    :modalVisible="modalGoalVisible"
    :data="selectedEditItem"
    :campaign="campaign"
    :appId="appId"
    @update:closeModal="onCloseModal"
    @update:onSelect="editCardDone"
    @update:onCreate="addCardDone"
  />
  <GoalSelectorModal
    v-if="modalSelectGoalVisible"
    :modalVisible="modalSelectGoalVisible"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelectNextGoalDone"
  />
  <FormFormModal
    v-if="modalFormVisible"
    :modalVisible="modalFormVisible"
    :data="selectedEditItem"
    :campaign="campaign"
    :appId="appId"
    @update:closeModal="onCloseModal"
    @update:onSelect="editCardDone"
    @update:onCreate="addCardDone"
  />
  <FormSelectorModal
    v-if="modalSelectFormVisible"
    :modalVisible="modalSelectFormVisible"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelectNextFormDone"
  />
  <HistorySelectorModal
    v-if="modalHistoryVisible"
    :modalVisible="modalHistoryVisible"
    :campaignId="campaignId"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelectHistoryDone"
  />
  <PreviewSelectorModal
    v-if="modalPreviewVisible"
    :modalVisible="modalPreviewVisible"
    :scenarios="value"
    @update:closeModal="onCloseModal"
  />
  <CopyScenariosModal
    v-if="modalCopyScenarios"
    v-model="value"
    :modalVisible="modalCopyScenarios"
    :appId="appId"
    :isTestMode="isTestMode"
    :campaignId="currentCampaignId"
    :campaignType="campaignType"
    :campaign="campaign"
    :selectedPushIndex="selectedPushIndex"
    :isReport="isReport"
    :story="story"
    @update:changeScenario="onChangeScenario"
    @update:closeModal="onCloseModal"
    @update:resetScenario="onResetScenario"
  ></CopyScenariosModal>

  <TransmissionLogModal
    v-if="modalTransmissionLogVisible"
    :modalVisible="modalTransmissionLogVisible"
    :campaignId="campaignId"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { SCENARIO_MAKER } from '@/utils/constants'
import { ICard } from 'bot-flow-maker/src/types'
import {
  EScenarioResourceType,
  IForm,
  IGoal,
  IInitialCampaign,
  IMessage,
  IPushCampaign,
  IQuestion,
  ISelectedItemCondition,
  IStory,
  IVersionHistory,
} from '@/utils/types'
import QuestionFormModal from '@/components/question/QuestionFormModal.vue'
import MessagesFormModal from '@/components/messages/MessagesFormModal.vue'
import GoalFormModal from '@/components/goal/GoalFormModal.vue'
import FormFormModal from '@/components/form/FormFormModal.vue'
import { ACTION_FORM, ACTION_GOAL, ACTION_MESSAGES, ACTION_QUESTION, VERSION_HISTORY } from '@/store/actions'
import PreviewSelectorModal from '@/components/scenarios/selectors/PreviewSelectorModal.vue'
import HistorySelectorModal from '@/components/scenarios/selectors/HistorySelectorModal.vue'
import moment from 'moment-timezone'
import StartPushFormModal from '@/components/story/common/StartPushFormModal.vue'
import { v4 as uuidv4 } from 'uuid'
import FormSelectorModal from '@/components/scenarios/selectors/FormSelectorModal.vue'
import GoalSelectorModal from '@/components/scenarios/selectors/GoalSelectorModal.vue'
import QuestionSelectorModal from '@/components/scenarios/selectors/QuestionSelectorModal.vue'
import MessageSelectorModal from '@/components/scenarios/selectors/MessageSelectorModal.vue'
import CopyScenariosModal from '@/components/story/copy-scenarios/CopyScenariosModal.vue'
import TransmissionLogModal from '@/components/story/TransmissionLogModal.vue'

@Options({
  components: {
    CopyScenariosModal,
    MessageSelectorModal,
    QuestionSelectorModal,
    GoalSelectorModal,
    FormSelectorModal,
    StartPushFormModal,
    QuestionFormModal,
    MessagesFormModal,
    GoalFormModal,
    FormFormModal,
    PreviewSelectorModal,
    HistorySelectorModal,
    TransmissionLogModal,
  },
  directives: { maska },
  emits: [
    'update:modelValue',
    'update:editingMode',
    'update:dateRange',
    'update:onChangeDirection',
    'update:onChangeScenarios',
    'update:editingStart',
    'update:openStartSettings',
    'update:campaignValue',
    'updateStartSettings',
  ],
})
export default class ScenariosMaker extends Vue {
  @Prop({ default: [] })
  modelValue!: ICard[]

  @Prop({ default: false })
  isReport!: boolean

  @Prop({ default: '' })
  campaignId!: string

  @Prop({ default: false })
  isTestMode!: boolean

  @Prop({ default: '0' })
  directionType!: string

  @Prop({ default: false })
  isCopy!: boolean

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  @Prop()
  campaignType!: string

  @Prop()
  selectedPushIndex!: number

  @Prop()
  previousCampaign!: IPushCampaign | IInitialCampaign

  @Prop()
  isOpenStartSettings!: boolean

  @Prop()
  isStoryFirstPush!: boolean

  @Prop()
  story!: IStory

  newCampaignId = uuidv4()
  modalStartVisible = false
  modalQuestionVisible = false
  modalSelectQuestionVisible = false
  modalMessageVisible = false
  modalSelectMessageVisible = false
  modalGoalVisible = false
  modalSelectGoalVisible = false
  modalFormVisible = false
  modalSelectFormVisible = false
  modalTransmissionLogVisible = false
  modalPreviewVisible = false
  modalHistoryVisible = false
  modalCopyScenarios = false

  selectedItemCondition: ISelectedItemCondition = {}
  selectedEditItem: IQuestion | IMessage | IGoal | IForm = {}
  titleLastHistory = ''
  isEditWelcome = false
  labelAllUser = ''

  get currentCampaignId() {
    if (!this.campaignId) {
      return this.newCampaignId
    }
    return this.campaignId
  }

  get value(): ICard[] {
    return this.modelValue
  }

  set value(val: ICard[]) {
    this.$emit('update:modelValue', val)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get messages() {
    return this.$store.getters.messages
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get forms() {
    return this.$store.getters.forms
  }

  get openStartSettings() {
    return this.isOpenStartSettings
  }

  set openStartSettings(value: boolean) {
    this.$emit('update:openStartSettings', value)
  }

  @Watch('campaignId', { immediate: true })
  async appIdChanged() {
    this.titleLastHistory = ''
    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.appId,
    })

    if (!this.isReport) {
      const currentVersion: IVersionHistory = await this.$store.dispatch(VERSION_HISTORY.LOAD_ITEM_LATEST, {
        app_id: this.appId,
        campaign_id: this.campaignId,
      })
      if (currentVersion && currentVersion._id) {
        const input: string = currentVersion?.default_title + ''
        const format = 'YYYY-MM-DD HH:mm:ss'
        const zone = 'Asia/Tokyo'
        const time = moment(moment.tz(input, format, zone).utc()).fromNow()

        this.titleLastHistory = this.$t('label.last_edit_was', { lastModified: time })
      }
    }
  }

  iframeURL() {
    let isBlankPush = true
    if (this.campaignType === 'push') {
      const push: IPushCampaign = this.campaign
      if (this.isTestMode) {
        if (push.test_detail?.schedule?.time && push.test_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      } else {
        if (push.prod_detail?.schedule?.time && push.prod_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      }
    }
    return this.$router.resolve({
      name: 'story_scenario_maker',
      params: {
        appId: this.appId,
        isReport: this.isReport,
        isTestMode: this.isTestMode,
        campaignType: this.campaignType,
        index: this.selectedPushIndex,
        campaignId: this.currentCampaignId,
        isReadOnly: false,
      },
      query: {
        isBlankPush: isBlankPush,
      },
    })?.fullPath
  }

  // eslint-disable-next-line
  postToIFrame(action: string, data: any) {
    // eslint-disable-next-line
    const iframeEl = document.getElementById('myFrame-' + this.campaignId) as any
    if (!iframeEl) {
      return
    }

    iframeEl.contentWindow.postMessage(
      {
        action,
        data: {
          data: JSON.stringify(data),
          isTestMode: this.isTestMode,
        },
      },
      '*'
    )
  }

  handleIFrameReady(isReadOnly = false) {
    let isStartNode = false
    let isWelcomeNode = false
    if (this.value) {
      const startCards = this.value.filter((item) => item.id === EScenarioResourceType.start)
      if (startCards && startCards.length > 0) {
        isStartNode = true

        if (this.isStoryFirstPush && this.campaignType === 'push') {
          startCards[0].isHideEditBtn = true
        }
      }
      const welcomeCards = this.value.filter((item) => item.uniqueId === EScenarioResourceType.welcome)
      if (welcomeCards && welcomeCards.length > 0) {
        isWelcomeNode = true
      }
    }

    if (!isStartNode) {
      const startCard: ICard = {
        id: EScenarioResourceType.start,
        uniqueId: EScenarioResourceType.start,
        displayId: '',
        // title: this.$t('label.start'),
        title: 'スタート',
        titleBadge: '',
        cardType: EScenarioResourceType.start,
        left: 100,
        top: 100,
        answers: [],
        totalUsers: 0,
        labelTotalUsers: '',
        isHideDeleteBtn: true,
      }
      if (this.campaignType === 'initial') {
        startCard.isHideAddBtn = true
        startCard.isHideEditBtn = true
        startCard.answers = [
          {
            id: 'any',
            // title: this.$t('label.any'),
            title: this.labelAllUser,
            nextCards: [
              {
                nodeIndex: 1,
                uniqueId: EScenarioResourceType.welcome,
              },
            ],
            totalUsers: 0,
          },
        ]
      }

      this.value.push(startCard)
    }

    if (!isWelcomeNode && this.campaignType === 'initial') {
      const welcomeCard: ICard = {
        id: '',
        uniqueId: EScenarioResourceType.welcome,
        displayId: '',
        title: 'ウェルカム',
        titleBadge: '',
        cardType: EScenarioResourceType.message,
        left: 100,
        top: 300,
        answers: [
          {
            id: 'any',
            title: this.labelAllUser,
            nextCards: [],
            totalUsers: 0,
          },
        ],
        totalUsers: 0,
        labelTotalUsers: '',
        isHideDeleteBtn: true,
      }
      this.value.push(welcomeCard)
    }
    if (isReadOnly) {
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.LOAD_SCENARIO, this.value)
    } else {
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, this.value)
    }
  }

  onChangeScenario() {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, this.value)
    this.handleIFrameReady()
  }

  onResetScenario(data: ICard[]) {
    this.value = data
    this.$emit('update:onChangeScenarios', data)
  }

  handleUpdateDataFromIFrame(event) {
    const data = JSON.parse(event?.data?.data)
    const id = event?.data?.campaignId
    if (id === this.currentCampaignId) {
      this.value = data
      this.$emit('update:onChangeScenarios', data)
    }
  }

  onChangeDirection(val) {
    this.$emit('update:onChangeDirection', val)
  }

  onCloseModal() {
    // Edit modal
    this.modalStartVisible = false
    this.modalQuestionVisible = false
    this.modalSelectQuestionVisible = false
    this.modalMessageVisible = false
    this.modalSelectMessageVisible = false
    this.modalGoalVisible = false
    this.modalSelectGoalVisible = false
    this.modalFormVisible = false
    this.modalSelectFormVisible = false
    this.modalTransmissionLogVisible = false
    this.modalPreviewVisible = false
    this.modalHistoryVisible = false
    this.modalCopyScenarios = false
    this.openStartSettings = false
  }

  updateStartSettings(value: IPushCampaign) {
    this.$emit('updateStartSettings', value)
  }

  editCardDone(resource) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.UPDATE_CARD, { resource })
  }

  addCardDone(card) {
    if (this.selectedItemCondition.cardType === EScenarioResourceType.message || !this.selectedItemCondition.cardType) {
      this.onSelectNextMessageDone(card)
    } else if (this.selectedItemCondition.cardType === EScenarioResourceType.question) {
      this.onSelectNextQuestionDone(card)
    } else if (this.selectedItemCondition.cardType === EScenarioResourceType.goal) {
      this.onSelectNextGoalDone(card)
    } else if (this.selectedItemCondition.cardType === EScenarioResourceType.form) {
      this.onSelectNextFormDone(card)
    }
    this.isEditWelcome = false
  }

  onCardEdit(uniqueId) {
    if (uniqueId === EScenarioResourceType.start) {
      if (!this.isStoryFirstPush) {
        this.modalStartVisible = true
      }
    } else {
      const card = this.value.find((item) => item.uniqueId === uniqueId)
      if (card) {
        if (card.cardType === EScenarioResourceType.question) {
          this.selectedEditItem = this.questions.find((item) => item._id === card.id) || {}
          this.modalQuestionVisible = true
        } else if (card.cardType === EScenarioResourceType.message) {
          this.selectedEditItem = this.messages.find((item) => item._id === card.id) || {}
          this.modalMessageVisible = true
        } else if (card.cardType === EScenarioResourceType.goal) {
          this.selectedEditItem = this.goals.find((item) => item._id === card.id) || {}
          this.modalGoalVisible = true
        } else if (card.cardType === EScenarioResourceType.form) {
          this.selectedEditItem = this.forms.find((item) => item._id === card.id) || {}
          this.modalFormVisible = true
        }
        this.isEditWelcome = card.uniqueId === EScenarioResourceType.welcome
      }
    }
  }

  onTransmissionLog() {
    this.modalTransmissionLogVisible = true
  }

  onOpenPreview() {
    this.modalPreviewVisible = true
  }

  onOpenHistory() {
    this.modalHistoryVisible = true
  }

  onOpenCopyScenarios() {
    this.modalCopyScenarios = true
  }

  onSelectHistoryDone(item: IVersionHistory) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ITEM_HISTORY, item)
  }

  addCard(resource, cardType: string, selectedItemCondition: ISelectedItemCondition) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ADD_CARD, { resource, cardType, selectedItemCondition })
  }

  openSelectNextQuestion(item: ISelectedItemCondition) {
    this.selectedEditItem = {
      app_id: this.appId,
    }
    this.selectedItemCondition = item
    this.modalSelectQuestionVisible = true
  }

  openSelectNextMessage(item: ISelectedItemCondition) {
    this.selectedEditItem = {
      app_id: this.appId,
    }
    this.selectedItemCondition = item
    this.modalSelectMessageVisible = true
  }

  openSelectNextGoal(item: ISelectedItemCondition) {
    this.selectedEditItem = {
      app_id: this.appId,
    }
    this.selectedItemCondition = item
    this.modalSelectGoalVisible = true
  }

  openSelectNextForm(item: ISelectedItemCondition) {
    this.selectedEditItem = {
      app_id: this.appId,
    }
    this.selectedItemCondition = item
    this.modalSelectFormVisible = true
  }

  onSelectNextQuestionDone(question: IQuestion) {
    this.addCard(question, EScenarioResourceType.question, this.selectedItemCondition)
    this.onCloseModal()
  }

  onSelectNextMessageDone(message: IMessage) {
    this.addCard(
      message,
      this.isEditWelcome ? EScenarioResourceType.welcome : EScenarioResourceType.message,
      this.selectedItemCondition
    )
    this.onCloseModal()
  }

  onSelectNextGoalDone(goal: IGoal) {
    this.addCard(goal, EScenarioResourceType.goal, this.selectedItemCondition)
    this.onCloseModal()
  }

  onSelectNextFormDone(form: IForm) {
    this.addCard(form, EScenarioResourceType.form, this.selectedItemCondition)
    this.onCloseModal()
  }

  handleMessage(event) {
    const action = event?.data?.action
    const isReadOnly = event?.data?.isReadOnly
    if (action === SCENARIO_MAKER.FROM_IFRAME.READY) {
      this.handleIFrameReady(isReadOnly)
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.DIRECTION, this.directionType)
      if (!this.isReport) {
        this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.MSG_LAST_HISTORY, this.titleLastHistory)
      }
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.IFRAME_NEW_DATA) {
      this.handleUpdateDataFromIFrame(event)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.IS_EDITING) {
      this.$emit('update:editingMode', !!event?.data.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.DATE_RANGE) {
      this.$emit('update:dateRange', event?.data.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_CARD_EDIT) {
      this.onCardEdit(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_LOG) {
      this.onTransmissionLog()
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_PREVIEW) {
      this.onOpenPreview()
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_QUESTION) {
      this.openSelectNextQuestion(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_MESSAGE) {
      this.openSelectNextMessage(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_GOAL) {
      this.openSelectNextGoal(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_FORM) {
      this.openSelectNextForm(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_CHANGE_DIRECTION) {
      this.onChangeDirection(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_HISTORY) {
      this.onOpenHistory()
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_COPY_SCENARIOS) {
      this.onOpenCopyScenarios()
    }
  }

  handleErrorCard(uniqueId) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ERROR_CARD, uniqueId)
  }

  handleKeyPress(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CTRL_Z, event)
    }
  }

  @Watch('isCopy')
  async onDetailCopy() {
    let scenarios: ICard[]
    if (this.isTestMode) {
      scenarios = this.campaign.prod_detail?.scenarios ?? []
    } else {
      scenarios = this.campaign.test_detail?.scenarios ?? []
    }
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, scenarios)
  }

  @Watch('isOpenStartSettings')
  openStartSettingModal() {
    if (this.openStartSettings && !this.isStoryFirstPush) {
      console.log(this.openStartSettings, 'openStartSettings')
      this.modalStartVisible = true
    }
  }

  mounted() {
    window.addEventListener('message', (event) => this.handleMessage(event))
    window.addEventListener('keydown', this.handleKeyPress)
  }

  beforeUnmount() {
    window.removeEventListener('message', (event) => this.handleMessage(event))
    window.removeEventListener('keydown', this.handleKeyPress)
  }

  created() {
    this.labelAllUser = this.$t('label.all_users')
    this.openStartSettingModal()
  }
}
</script>
<style lang="scss" scoped>
.scenario-iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}
</style>
