<template>
  <div class="q-mt-md">
    <div class="row" v-if="value === 0">
      <div class="col q-pr-xs">
        <q-btn unelevated text-color="grey" icon="add" size="lg" class="full-width dotted-grey-border" />
      </div>
    </div>
    <div v-if="value !== 0">
      <div
        class="row"
        v-if="
          form.trigger_type === 'default' || form.trigger_type === 'qr_code' || form.trigger_type === 'popup_deliver'
        "
      >
        <div
          class="col dotted-grey-border cursor-pointer"
          :class="value === 1 ? form.trigger_type + '-active ' + form.trigger_type : form.trigger_type"
          style="position: relative"
          @click="value = 1"
        >
          <div class="text-caption">{{ $t('label.story.trigger') }}</div>
          <div class="row full-width q-ma-sm" style="gap: 8px">
            <q-icon
              name="person"
              class="flag-icon flag-icon__default q-ml-sm"
              size="1.5em"
              v-if="form.trigger_type === 'default'"
            />
            <q-icon name="flag" class="flag-icon flag-icon__popup_deliver q-ml-sm" size="1.5em" v-else />

            <div class="text-subtitle1 text-bold">
              {{ $t('label.story.' + form.trigger_type + '_trigger') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="form.trigger_type === 'audience'">
        <div
          class="col dotted-grey-border cursor-pointer audience"
          :class="value === 1 ? 'audience-active' : ''"
          style="position: relative"
          @click="value = 1"
        >
          <div class="text-caption">{{ $t('label.story.trigger') }}</div>
          <div class="row full-width q-ma-sm" style="gap: 8px">
            <q-icon name="forum" class="flag-icon flag-icon__audience q-ml-sm" size="1.5em" />
            <div class="text-subtitle1 text-bold">{{ $t('label.story.audience_trigger') }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="
          (form.trigger_type !== 'default' && value > 1) ||
          ((form.trigger_type === 'qr_code' || form.trigger_type === 'popup_deliver') &&
            ((form._id !== '' && form.campaign?.prod_detail) || form.popups?.length)) ||
          (form.trigger_type === 'audience' && form._id !== '' && form.push?.title)
        "
        class="vertical-line"
      ></div>
      <div
        class="row"
        v-if="
          (form.trigger_type === 'qr_code' || form.trigger_type === 'popup_deliver') &&
          (value > 1 || (form._id !== '' && form.campaign?.prod_detail) || form.popups?.length)
        "
      >
        <div
          class="col dotted-grey-border cursor-pointer"
          :class="value === 2 ? form.trigger_type + '-active ' + form.trigger_type : form.trigger_type"
          style="position: relative"
          @click="value = 2"
        >
          <div class="text-caption">サイト内</div>
          <div class="row full-width q-ma-sm" style="gap: 8px">
            <q-icon name="flag" class="flag-icon flag-icon__popup_deliver q-ml-sm" size="1.5em" />
            <div class="text-subtitle1 text-bold">ポップアップ</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="form.trigger_type === 'audience' && (value > 1 || (form._id !== '' && form.push?.title))">
        <div
          class="col dotted-grey-border cursor-pointer audience"
          :class="value === 2 ? 'audience-active' : ''"
          style="position: relative"
          @click="value = 2"
        >
          <div class="text-caption">ユーザー</div>
          <div class="row full-width q-ma-sm" style="gap: 8px">
            <q-icon name="person" class="flag-icon flag-icon__audience q-ml-sm" size="1.5em" />
            <div class="text-subtitle1 text-bold">オーディエンス</div>
          </div>
        </div>
      </div>
      <div
        v-if="
          (form.trigger_type === 'default' ||
            form.trigger_type === 'qr_code' ||
            form.trigger_type === 'popup_deliver') &&
          (value > 2 || (form._id !== '' && form.initial?.title))
        "
        class="vertical-line"
      ></div>
      <div
        class="row"
        v-if="
          (form.trigger_type === 'default' ||
            form.trigger_type === 'qr_code' ||
            form.trigger_type === 'popup_deliver') &&
          (value > 2 || (form._id !== '' && form.initial?.title))
        "
      >
        <div
          class="col dotted-grey-border cursor-pointer initial"
          :class="value === 3 ? 'initial-active' : ''"
          style="position: relative"
          @click="value = 3"
        >
          <div class="text-caption">{{ $t('label.story.scenario') }}</div>
          <div class="row full-width q-ma-sm" style="gap: 8px">
            <q-icon name="history_edu" class="flag-icon flag-icon__initial q-ml-sm" size="1.5em" />
            <div class="text-subtitle1 text-bold">初回メッセージ</div>
          </div>
        </div>
      </div>

      <div v-for="(item, index) in form.pushes" :key="index">
        <div
          v-if="
            (form.trigger_type === 'audience' && value >= 2) || value > 3 || (form._id !== '' && form.pushes?.length)
          "
        >
          <div class="vertical-line" style="height: 8px"></div>
          <StartSettingBtnSelector
            v-if="isTestMode"
            :detail="item.test_detail"
            :form="form"
            :index="index"
            @openStartSettings="openStartSettings"
          ></StartSettingBtnSelector>
          <StartSettingBtnSelector
            v-else
            :detail="item.prod_detail"
            :form="form"
            :index="index"
            @openStartSettings="openStartSettings"
          ></StartSettingBtnSelector>
          <div class="vertical-line" style="height: 8px"></div>
        </div>
        <div
          class="row"
          v-if="
            (form.trigger_type === 'audience' && value >= 2) || value > 3 || (form._id !== '' && form.pushes?.length)
          "
        >
          <div
            class="col dotted-grey-border cursor-pointer"
            :class="value === 4 && pushIndex === index ? 'push-active' : ''"
            style="position: relative"
          >
            <q-btn
              size="6px"
              round
              flat
              color="white"
              text-color="red"
              icon="delete"
              class="dotted-border step-delete"
              @click="deletePush(index)"
              style="border-color: red; position: absolute; top: 12px; right: 8px"
              v-if="(form.trigger_type === 'audience' && index !== 0) || form.trigger_type !== 'audience'"
            ></q-btn>
            <div class="text-caption cm-black-text">{{ $t('label.story.scenario') }}</div>
            <div class="row full-width q-ma-sm" style="gap: 8px" @click="selectPush(index)">
              <q-icon name="history_edu" class="flag-icon flag-icon__push q-ml-sm" size="1.5em" />
              <div class="text-subtitle1 text-bold">
                {{
                  item.title
                    ? item.title.length > 20
                      ? item.title.slice(0, 20) + '...'
                      : item.title
                    : 'メッセージ：' + (index + 2)
                }}
              </div>
              <q-tooltip>{{ item.title ? item.title : 'メッセージ：' + (index + 2) }}</q-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="vertical-line" v-if="form.pushes?.length < 19"></div>
      <div class="row" v-if="form.pushes?.length < 19">
        <div class="col q-pr-xs q-pb-md">
          <q-btn
            unelevated
            text-color="grey"
            icon="add"
            size="lg"
            class="full-width dotted-grey-border"
            @click="nextStep"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { IStory } from '@/utils/types'
import StartSettingBtnSelector from '@/components/story/common/selectors/StartSettingBtnSelector.vue'

@Options({
  components: { StartSettingBtnSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:selectedPushIndex', 'nextStep', 'deletePush', 'openStartSettings'],
})
export default class Stepper extends Vue {
  @Prop()
  modelValue!: number

  @Prop()
  form!: IStory

  @Prop()
  selectedPushIndex!: number

  @Prop({ default: false })
  isTestMode = false

  get value() {
    return this.modelValue
  }

  set value(value: number) {
    this.$emit('update:modelValue', value)
  }

  get pushIndex() {
    return this.selectedPushIndex
  }

  set pushIndex(value: number) {
    this.$emit('update:selectedPushIndex', value)
  }

  nextStep() {
    this.$emit('nextStep')
  }

  selectPush(index: number) {
    this.value = 4
    this.pushIndex = index
  }

  deletePush(index: number) {
    this.$emit('deletePush', index)
  }

  openStartSettings(index) {
    this.value = 4
    this.pushIndex = index
    this.$emit('openStartSettings')
  }
}
</script>

<style scoped lang="scss">
.dotted-grey-border {
  margin-right: 4px;
}
.text-caption {
  position: absolute;
  top: 0;
  left: 38px;
  font-size: 8px;
}
.text-subtitle1 {
  font-size: 12px;
  font-weight: 700;
}
.vertical-line {
  border-right: 1px solid #d9d9d9;
  height: 36px;
  width: 50%;
}
.horizontal-dotted-line {
  border-bottom: 1px dotted grey;
  height: 50%;
}
</style>
